.portfolio-item {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    background-size: cover;
    background-position: center;
    aspect-ratio: 0.72 / 1;
    padding: calc($vertical-spacing * 2) calc($horizontal-spacing * 2);
    color: $white;
    margin-bottom: $vertical-spacing;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000000, .2);
        z-index: 1;
    }

    &>* {
        z-index: 2;
    }

    &__title {
        font-family: $strapline-font;
        font-weight: 400;
        font-size: 1rem;

        @include media-min(lg) {
            font-size: 1.8rem;
        }
    }

    svg {
        fill: $white;
        font-size: calculateRem(30px);
    }
}


.portfolio-company {
    background-color: $brand-primary-orange;
    aspect-ratio: 16/9;
    width: 100%;
    position: relative;
    margin: $vertical-spacing 0;

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        opacity: 0;
        flex-wrap: wrap;
        text-align: center;
        padding: $vertical-spacing $horizontal-spacing;

        * {
            color: $white;
            width: 100%;
        }
    }

    svg, img {
        position: absolute;
        top: $vertical-spacing;
        left: $horizontal-spacing;
        width: calc(100% - ($horizontal-spacing * 2));
        height: calc(100% - ($vertical-spacing * 2));
        object-fit: contain;
    }

    .tquila-automation__logo svg {
        fill: white;
    }

    * {
        transition: opacity 300ms;
    }

    &:hover {
        .portfolio-company__content {
            opacity: 1;
        }

        svg, img {
            opacity: 0;
        }
    }
}

.portfolio-item-container {
    .portfolio-img {
        width: 100%;
        border-top-right-radius: 50px;
        max-height: 850px;
        object-fit: cover;
    }
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: $grid-gutters;
}

tr:nth-child(even) {
    background-color: #dddddd;
}