@use 'sass:map';

@mixin media-min($breakpoint) {
    @media (min-width: map.get($breakpoints, $breakpoint)) {
        @content;
    }
}

@mixin media-max($breakpoint) {
    @media (max-width: (map.get($breakpoints, $breakpoint) - 0.2px)) {
        @content;
    }
}