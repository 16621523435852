.text-centre {
    text-align: center;
}

.text-right {
    text-align: right;
}

.full-width {
    width: 100%;
}

.flex {
    display: flex;
}

.align-centre {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.align-stretch {
    align-items: stretch;
}

.align-end {
    align-items: flex-end;
}

.align-self-centre {
    align-self: center;
}

.justify-centre {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.full-width {
    width: 100%;
}

.vh-100 {
    min-height: 100vh;
}

.vh-75 {
    min-height: 75vh;
}

.vh-50 {
    min-height: 50vh;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.image-container--1-1 {
    @include image-container(1, 1);
}

.image-container--4-3 {
    @include image-container(4, 3);
}

.image-container--16-9 {
    @include image-container(16, 9);
}

.video-wrapper {
    @include image-container(16, 9);
}

.aspect-ratio-box-1-1 {
    @include aspect-ratio-box(1, 1);
}

.aspect-ratio-box-4-3 {
    @include aspect-ratio-box(4, 3);
}

.aspect-ratio-box-16-9 {
    @include aspect-ratio-box(16, 9);
}

.aspect-ratio-box-16-6 {
    @include aspect-ratio-box(16, 4);
}

.aspect-ratio-box-4-1 {
    @include aspect-ratio-box(4, 1);
}

.highlight {
    display: inline;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 85%, #DB7931 85%);
}

.strapline {
    text-transform: uppercase;
    color: $brand-primary-orange;
    font-weight: bold;
    font-size: calculateRem(13px);
    font-family: $strapline-font;
}

.border-black {
    border: 1px solid $black;
}

.border-orange {
    border: 1px solid $brand-primary-orange;
}

.colour-orange {
    color: $brand-primary-orange;
}

.colour-black {
    color: $black;
}

.colour-white {
    color: $white;
}

.horizontal-padding {
    padding-left: $horizontal-spacing;
    padding-right: $horizontal-spacing;
}

.vertical-margin {
    margin-top: $vertical-spacing;
    margin-bottom: $vertical-spacing;
}

.z1 {
    z-index: 1;
}

.z2 {
    z-index: 2;
}

.z3 {
    z-index: 3;
}

.overflow-hidden {
    overflow: hidden;
}

.background-no-repeat {
    background-repeat: no-repeat;
}

.background-centre {
    background-position: center;
}

.background-cover {
    background-size: cover;
}

.curved-image {
    border-top-right-radius: 50px;
}