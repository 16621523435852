body {
    font-family: $base-font;
    color: $paragraph-colour;
    font-weight: 400;
    line-height: 1.35;
    font-size: $base-font-size;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $header-font;
    margin-top: 1rem;
}

h1 {
    font-size: calculateRem(57px);

    @include media-max(md) {
        font-size: calculateRem(32px);
    }
}

h2 {
    font-size: calculateRem(35px);
    
    @include media-max(md) {
        font-size: calculateRem(24px);
    }
}

h3 {
    font-size: calculateRem(28px);

    @include media-max(md) {
        font-size: calculateRem(22px);
    }
}

p {
    font-weight: 500;
    
    @include media-max(md) {
        font-size: calculateRem(14px);
    }
}

a {
    color: $link-colour;
    font-weight: 500;
    text-decoration: none;
    transition: opacity 300ms;
    letter-spacing: 1.8px;
    font-weight: bold;

    &:hover {
        opacity: .7;
    }
}

h1 a, h2 a, h3 a, h4 a, h5 a {
    letter-spacing: normal;
}

li {
    padding-bottom: $grid-gutters;
}