@function ratio($width, $height, $container-width: 100%) {
    @return calc($height / $width * $container-width);
}

@mixin image-container($width, $height) {
    position: relative;
    overflow: hidden;
    padding-top: ratio($width, $height);
    
    img, iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

@mixin aspect-ratio-box($width, $height) {
    width: 100%;
    
    &::before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: ratio($width, $height, 100%);
    }

    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@function calculateRem($size) {
    $remSize: calc($size / $base-font-size);
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}