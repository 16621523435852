%number-list {
    list-style-type: none;
    counter-reset: index;
    position: relative;
    padding-left: 5rem;

    li {
        margin: $vertical-spacing 0;

        * {
            margin: 0;
        }

        &::before {
            counter-increment: index;
            content: "0"counter(index);
            position: absolute;
            left: 0;
            font-family: $strapline-font;
            font-size: calculateRem(50px);
            color: $brand-primary-orange;
            margin-top: -.5rem;

            
        }

        &:nth-child(n + 10) {
            &::before {
                content: counter(index);
            }
        }
    }
}

.number-list {
    @extend %number-list;

    @at-root &--white {
        @extend %number-list;

        li {
            &::before {
                color: $white;
            }
        }
    }
}