.people-item {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    background-size: cover;
    background-position: center;
    aspect-ratio: 0.72 / 1;
    padding: calc($vertical-spacing * 2) calc($horizontal-spacing * 2);
    color: $white;
    
    &__title {
        font-family: $strapline-font;
        font-weight: 400;
        font-size: 1rem;
        
        @include media-min(lg) {
            font-size: 1.5rem;
        }
    }

    svg {
        fill: $white;
        font-size: calculateRem(30px);
    }
    
    &__role {
        text-transform: uppercase;
    }
}