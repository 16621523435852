.mobile-expand {
    @include media-max(lg) {
        overflow: hidden;
    }
}

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $brand-secondary-navy;
    z-index: 100;
    overflow-y: auto;
    padding: calc($vertical-spacing * 3) 0 $vertical-spacing 0;
    transform: translateX(100%);
    transition: transform 400ms ease-out;

    .mobile-expand & {
        transform: translateX(0);
    }
    
    &__navigation {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        padding: 0 $grid-gutters;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__navigation-item {
        width: 100%;
        font-size: calculateRem(19px);
        text-transform: uppercase;
        padding: 0;
        margin: $vertical-spacing 0;
    }
    
    &__navigation-seperator {
        padding: 0;
        width: 100%;
        border-bottom: 1px solid $brand-primary-orange;
        margin-top: auto;
    }

    &__navigation-contact {
        * {
            color: $brand-primary-orange;
        }
    }
}