/* Media Queries */
$breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px);

/* Grid */
$container-width: 1640px;
$grid-gutters: 20px;

/* Colours */
$onyx: #363946ff;
$dim-gray: #696773ff;
$cadet-gray: #819595ff;
$ash-gray: #b1b6a6ff;

//Brand colours
$brand-primary-orange: #DB7931;
$brand-secondary-navy: #001325;
$bright-blue: #CFD4DA;
$off-white: #ECECE7;
$light-grey: #DBDADB;

$brand-secondary-cyan: #BCFDFE;

$brand-secondary-cyan-alt: #58D1D3;

$black: #0B1C26;
$white: #FFFFFF;


/* Typography */
$base-font: 'Montserrat', sans-serif;
$header-font: 'Montserrat', sans-serif;

$strapline-font: 'Red Hat Display', sans-serif;

$paragraph-colour: $black;

$link-colour: $black;

$base-font-size: 16px;
$small-print: font-size(12px);


/* Spacing */
$vertical-spacing: 1rem;
$horizontal-spacing: 1.5rem;