.flow-box {
    display: flex;
    align-items: center;

    &__inner {
        aspect-ratio: 16/9;
        background-image: url('/wwwroot/images/border.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 2rem 3rem;
        position: relative;
        margin-bottom: 30%;
        
        @include media-min(lg) {
            margin-bottom: 0;
        }

        &::after {
            content: '';
            aspect-ratio: 16/9;
            width: 25%;
            position: absolute;
            background-image: url('/wwwroot/images/big_arrow.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            top: 100%;
            right: 50%;
            transform: translate(50%, 80%) rotate(90deg);
            
            @include media-min(lg) {
                margin-bottom: 0;
                right: 0;
                top: 50%;
                transform: translate(140%, -50%);
            }
        }
    }

    @include media-min(lg) {
        &__inner {
            margin-left: 10%;
            margin-right: 10%;
        }

        &:first-of-type &__inner {
            margin-right: 20%;
        }

        &:last-of-type &__inner {
            margin-left: 20%;

            &::after {
                display: none;
            }
        }
    }

    &:last-of-type &__inner {
        margin-bottom: 0;
        
        &::after {
            display: none;
        }
    }
}