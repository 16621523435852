.footer {
    color: $white;
    padding: calc($vertical-spacing * 2) 0;
    background-color: $brand-secondary-navy;
    font-size: calculateRem(13px);

    &__nav {
        list-style-type: none;
        margin: 0;
        margin-top: $vertical-spacing;
        padding: 0;
    }

    &__socials {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
        }

        @include media-max(lg) {
            margin-top: $vertical-spacing;
        }
    }

    &__social-actual {
        border-radius: 100%;
        border: 1px solid $white;
        display: block;
        position: relative;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-max(lg) {
            height: 50px;
            width: 50px;
        }

        svg {
            fill: $brand-primary-orange;

            @include media-min(lg) {
                font-size: .8rem;
            }
        }
    }
}