//Imports
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Red+Hat+Display:wght@400;500;600&display=swap');
@import '~slick-carousel/slick/slick.css';

@import 'vars';
@import 'util/mixins';
@import 'util/default';

/* Utilities */
@import 'util/normalize';
@import 'util/mq';
@import 'util/grid';
@import 'util/utilities';

/* Animations */
@import 'animations';

/* Base */
@import 'base';
@import 'typography';
@import 'form';
@import 'button';
@import 'sliders';

/* Layout */
@import 'layout/header';
@import 'layout/footer';
@import 'layout/mobile-nav';

/* Components */
@import 'components/block';
@import 'components/image-card';
@import 'components/our-people';
@import 'components/portfolio_item';
@import 'components/news-item';
@import 'components/number-list';
@import 'components/flow-box';