.header {
    background-color: $brand-secondary-navy;
    color: $white;
    padding: $vertical-spacing 0;
    
    * {
        color: $white;
    }
    
    &__logo {
        margin: $vertical-spacing 0;
    }
    
    &__navigation {
        padding: 0;
        margin: 0;
        list-style-type: none;
        text-align: right;
    }
    
    &__navigation-item {
        position: relative;
        display: inline-block;
        margin: $vertical-spacing calc($horizontal-spacing / 2);
        padding: 0;

        a {
            text-transform: uppercase;
            font-size: calculateRem(12px);

            &.current {
                color: $brand-primary-orange;
            }
        }

        &:hover {
            .header__submenu {
                display: block;
            }
        }
    }

    &__submenu {
        display: none;
        position: absolute;
        left: 0;
        padding: 0;
        margin: 0;
        list-style-type: none;
        background: $brand-secondary-navy;
        text-align: left;

        * {
            text-align: left;
            white-space: nowrap;
        }
    }

    &__navigation-seperator {
        border-left: 1px solid $brand-primary-orange;
        align-self: stretch;
        margin: 0 $horizontal-spacing;
    }

    &__navigation-contact {
        a {
            color: $brand-primary-orange;
        }
    }

    &__mobile-navigation {

    }

    &__hamburger {
        position: relative;
        height: 10px;
        width: 25px;
    }

    &__burger {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: $brand-primary-orange;
        height: 2px;

        &:last-child {
            top: 100%;
        }
    }
}