%btn {
    background: none;
    border: 1px solid black;
    cursor: pointer;
    padding: .6rem 2rem;
    display: inline-block;
    font-size: calculateRem(12px);
    text-transform: uppercase;

    &.full-width {
        width: 100%;
        max-width: none;
    }
}

.btn {
    @extend %btn;
    
    @at-root &--white {
        @extend %btn;
        color: $white;
        border-color: $white;
    }

    @at-root &--orange {
        @extend %btn;
        color: $white;
        background-color: $brand-primary-orange;
        border-color: $brand-primary-orange;
    }
}