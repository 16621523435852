.news-item {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    background-size: cover;
    background-position: center;
    //aspect-ratio: 0.72 / 1;
    padding: calc($vertical-spacing * 2) calc($horizontal-spacing * 2);
    margin-bottom: $vertical-spacing;
    position: relative;
    background-color: #ECECE7;

    &:nth-child(odd) {
        background: $white;
    }

    & > * {
        z-index: 2;
    }

    &__title {
        font-family: $strapline-font;
        font-weight: 400;
        font-size: 1rem;

        @include media-min(lg) {
            font-size: 1.8rem;
        }
    }

    svg {
        fill: $white;
        font-size: calculateRem(30px);
    }
}

.news-item-container {
    background-color: $off-white;
    aspect-ratio: 16/9;
    width: 100%;
    position: relative;
    margin: $vertical-spacing 0;
    position: relative;
    border-top-right-radius: 25px;

    img {
        position: absolute;
        top: $vertical-spacing;
        left: $horizontal-spacing;
        width: calc(100% - ($horizontal-spacing * 2));
        height: calc(100% - ($vertical-spacing * 2));
        object-fit: contain;
    }
}
