

%block {
    min-height: 20vh;
    padding: ($vertical-spacing * 3) 0;
    
    @include media-max(md) {
        padding: ($vertical-spacing * 2) 0;
    }
}

.block {
    @extend %block;
    
    @at-root &--image {
        @extend %block;
        background-size: cover;
        background-position: center;
        color: $white;
    }

    @at-root &--overlap {
        @extend %block;
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 55%,
        $white 55%);
    }
}

%header-block {
    
    .header-block__header {
        text-transform: uppercase;
        border-bottom: 1px solid $brand-secondary-navy;

        h5 {
            margin: $vertical-spacing 0;
        }
    }

    .header-block__btns {
        margin: 0;
        padding: 0;
        list-style-type: none;

    }
    
    .header-block__btn {
        padding: $vertical-spacing $horizontal-spacing;
        border-left: 1px solid $brand-secondary-navy;
        display: flex;
        align-items: center;
        
        * {
            margin: 0 !important;
        }
        
        &:last-child {
            @include media-min(md) {
                border-right: 1px solid $brand-secondary-navy;
            }
        }
    }
}

.header-block {
    @extend %header-block;
    
    @at-root &--light {
        @extend %header-block;
        background-color: $off-white;
    }
    
    @at-root &--chrome {
        @extend %header-block;
        background-color: $bright-blue;
    }

    @at-root &--navy {
        @extend %header-block;
        background-color: $brand-secondary-navy;
        color: $white;
        fill: $white;

        .header-block__header, .header-block__btn {
            border-color: $white;
        }
    }
}