input[type=text], input[type=password], input[type=email], input[type=search], textarea {
    display: inline-block;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid $brand-secondary-navy;
    padding: .3rem;
    background-color: transparent;
    resize: none;
    padding: .75rem 0;
    margin: $vertical-spacing 0;
}

form {
    display: contents;
}