.slick-arrow-btn {
    border-radius: 100%;
    background-color: $brand-primary-orange;
    position: relative;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    border: 2px solid $brand-primary-orange;
    cursor: pointer;
    margin: $vertical-spacing .25rem;
    transition: 300ms;

    &:hover {
        opacity: 0.8;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: $white;
    }

    &.disabled {
        background-color: transparent;
        cursor: auto;
        opacity: .6;
        pointer-events: none;

        svg {
            fill: $brand-primary-orange;
        }
    }
}

%overflow-slider {
    @include media-min(lg) {
        padding: calc($vertical-spacing * 3);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            z-index: 1;
            transform: translateX(-100%);
            pointer-events: none;
        }

        .slick-slide {
            margin: 0 $horizontal-spacing;
        }

        .slick-list {
            overflow: visible;
            margin: 0 (-$horizontal-spacing);
        }
    }

    .slick-slide {
        margin: 0 calc($horizontal-spacing / 2);
    }
}

.overflow-slider {
    @extend %overflow-slider;

    &::before {
        background-color: $white;
    }

    @at-root &--navy {
        @extend %overflow-slider;

        &::before {
            background-color: $brand-secondary-navy;
        }
    }
    
    @at-root &--chrome {
        @extend %overflow-slider;

        &::before {
            background-color: $bright-blue;
        }
    }
}