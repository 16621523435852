.image-card {
    @include aspect-ratio-box(16, 6);
    background-size: cover;
    background-repeat: no-repeat;
    border-top-right-radius: 70px;
    display: flex;
    align-items: center;
    padding: $vertical-spacing calc($horizontal-spacing * 3);
    
    @include media-max(md) {
        padding: calc($vertical-spacing * 5) $horizontal-spacing;
    }

    &__container {
        color: $white;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 33.33%;

        @include media-max(md) {
            width: 80%;
        }
    }
}